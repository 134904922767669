<template>
  <div id="app">
    <!--<vue-pull-refresh :on-refresh="onRefresh" :config="config"> -->
    <router-view />
    <ClicCopilot />
    <!-- <ClicSession/> -->
    <!-- </vue-pull-refresh> -->
    <ClicNotificationInterno />
  </div>
</template>

<script>

import Vue from 'vue';
import VuePullRefresh from 'vue-pull-refresh';
import socketDefaultService from './services/socketDefaultService';
import socketServerService from './services/socketServerService';
import socketTenantService from './services/socketTenantService.js';


export default {

  components: {
    'vue-pull-refresh': VuePullRefresh,
  },

  data() {
    return {
      config: {
        errorLabel: "Ocorreu um erro ao atualizar, tente novamente mais tarde...",
        startLabel: "Puxe para recarregar a página",
        readyLabel: "A página será recarregada",
        loadingLabel: "Carregando...",
        pullDownHeight: "120",
      },
    }
  },

  created() {
    const subdominio = (this.$host && this.$host.subdominio);
    Vue.prototype.$socket = socketTenantService.socketTenant;
    if (subdominio == "admfw") {
      Vue.prototype.$globalSocket = socketDefaultService.socketDefault;
      Vue.prototype.$serverSocket = socketServerService.socketServer;
    }
    socketDefaultService.setupSocketConnection();
  },

  mounted() {
    this.setFavicon();
  },

  beforeUnmount() {
    socketDefaultService.disconnect();
  },

  methods: {
    onRefresh() {
      document.location.reload();

      const postMessage = {
        type : "pageRefresh"
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(postMessage));
    },

    setFavicon() {
      document.querySelectorAll("link[rel~='icon']").forEach(link => link.remove());
      const link = document.createElement("link");
      link.rel = "icon";
      link.type = "image/x-icon";
      const timestamp = new Date().getTime();
      link.href = `${process.env.VUE_APP_BUCKET_URL}/${Vue.prototype.$host.subdominio}/imagens/identidade/favicon.png`;
      document.head.appendChild(link);
    }

  }
};
</script>


<style></style>

<style lang="scss">
/*
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100vw;
    height: 100vh;
  }
*/

#app {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
