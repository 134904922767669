import TokenService from "./tokenService.js";

let isAtualizandoToken  = false;
let filaRequisicoes     = [];

const setup = (store, axios) => {    
    axios.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            config.baseURL  = process.env.VUE_APP_BACKEND_URL;

            try {
                const apiBaseDomain = ".clictecnologia.com.br";
                const url = new URL(config.url, window.location.origin);
                const isSameDomain = url.hostname.endsWith(apiBaseDomain) || url.hostname === "localhost";
                config.withCredentials = isSameDomain;

            } catch (error) {
                console.log("Erro ao processar URL da requisição (validação withCredentials): ", error);
                config.withCredentials = false;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            let originalConfig = err.config;

            if  (err.response.status == 401) {

                if (originalConfig.url === "/auth/refresh") {
                    store.dispatch('auth/logout');
                    return Promise.reject(err);
                
                } else {

                    if  (!isAtualizandoToken) {
                        isAtualizandoToken = true;
                        return doCallRefreshToken(axios, originalConfig, store);
                    }

                    return new Promise(function(resolve, reject) {
                        filaRequisicoes.push({ resolve, reject });
                    }).then(token => {     
                        originalConfig.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalConfig);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
                }
            }
            return Promise.reject(err);
        }
    );
};

const doCallRefreshToken = (axios, originalConfig, store) => {
    return new Promise(function(resolve, reject) {
        axios
            .post("/auth/refresh")
            .then(({ data }) => {
                const { accessToken } = data;
                store.dispatch('auth/refreshToken', accessToken);
                TokenService.updateLocalAccessToken(accessToken);
                
                axios.defaults.headers['Authorization'] = 'Bearer ' + accessToken;
                originalConfig.headers['Authorization'] = 'Bearer ' + accessToken;
                processarFilaRequisicoes(null, accessToken);
                resolve(axios(originalConfig));
            })
            .catch(err => {
                processarFilaRequisicoes(err, null);
                store.dispatch('auth/logout');
                reject(err);
            })
            .then(() => {
                isAtualizandoToken = false;
            });
    });
}

const processarFilaRequisicoes = (error, token = null) => {
    filaRequisicoes.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    filaRequisicoes = [];
};


export default setup;