import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicListDocs from './ClicListDocs'

export default {
    ClicList : {
        config      : {icon: "md-viewlist", isContainer: true, documentation: ClicListDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            items               : {type: 'array',       default: [],            component: { name : 'input' } },
            headerTitle         : {type: 'string',      default: "",            component: { name : 'input' } },

            itemStyle                       : {type: 'string',      default: undefined, component: { name : 'input' } },
            pathItems                       : {type: 'string',      default: undefined, component: { name : 'input' } },
            itemsPerPage                    : {type: 'number',      default: 10,        component: { name : 'input' } },
            scrollToTop                     : {type: 'boolean',     default: false,     component: { name : 'switch' } },
            sortBy                          : {type: 'string',      default: undefined, component: { name : 'input' } },
            sortDesc                        : {type: 'boolean',     default: true,      component: { name : 'switch' } },
            configAxiosContent              : {type: 'string',      default: undefined, component: { name : 'code' } },
            keysFilter                      : {type: 'array',       default: [],        component: { name : 'input' } },
            
            ...ClicColInterno   
        },
        events : {
            click           : {type: 'string', component: { name : 'code' } },
            clickPromise    : {type: 'string', component: { name : 'code' } },
        }
    }
}