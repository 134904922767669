<template>
  <ClicColInterno 
    md="12" 
    :cp="cp" 
    :key="componentKey" 
    containerStyle="height: 100%;width: 100%;"
  >
    <template>
      <MotorS 
        ref="rowFiltros" 
        :cp="cp" 
        :childrens="filterElements" 
        :idTela="idTela" 
      />
    </template>
    <b-row 
      style="height: 100%;"
    >
      <b-col v-for="chart in chartElements" 
        :key="chart.chartId" 
        :id="chart.chartId" 
        :sm="chart.sm || ''"
        :md="chart.md || ''" 
        :lg="chart.lg || ''" 
        :xl="chart.xl || ''" 
        class="p-1" 
        style="height: 50%;"
      />
    </b-row>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import storage from '../../store/modules/storage';
import axios from 'axios';
import lodash from 'lodash';

export default {
  name: 'ClicMongoDBChart',
  mixins: [ComponentMixin],
  data() {
    return {
      public: {
        dataRefresh: this.obterMongoDashboard,
        applyFilters: this.applyFilters
      },
      filter: [],
      filterElements: [],
      charts: [],
      chartElements: [],
      filtrosPadrao: {},
    };
  },

  mounted() {
    this.obterMongoDashboard()
  },
  methods: {
    async obterMongoDashboard() {
      if (this.clicDashboardId) {
        const options = {
          method: 'GET',
          url: `${process.env.VUE_APP_BACKEND_URL}/api/mongodashboard`,
          headers: { "content-type": "application/json" },
          params: { subdominio: this.$host.subdominio, id: this.clicDashboardId }
        };

        try {
          const response = await axios.request(options);
          const clicDashboard = response.data.dados?.dashboards;
          this.charts = clicDashboard?.charts.sort((a, b) => a.position - b.position);
          this.generateDashboard()
          this.filters = clicDashboard?.filtros.sort((a, b) => a.configuracoes?.ordem - b.configuracoes?.ordem);
          this.setFilters()
        } catch (e) {
          console.error("[ClicMongoDBChart] Erro ao buscar o dashboard:", e);
        }
      }
    },
    async generateDashboard() {
      if (this.charts) {
        this.chartElements = [];
        let chartPromises = this.charts.map(chart => this.createChartElement({ ...chart }));
        
        await Promise.all(chartPromises);
        this.applyFilters(this.filtrosIniciais);
      }
    },
    createChartElement(parametros) {
      return new Promise((resolve, reject) => {
        if (!this.baseUrl || !parametros.chartId) {
          console.error("[ClicMongoDBChart] Parâmetros baseUrl ou chartId são obrigatórios.");
          return reject();
        }

        if (parametros.visible) {
          const chartId = `chart-${parametros.chartId}-${this.chartElements.length}`;
          this.chartElements.push({ ...parametros, chartId });

          this.$nextTick(() => {
            const sdk = new ChartsEmbedSDK({
              baseUrl: this.baseUrl,
              chartId: parametros.chartId,
              ...parametros
            });

            this.loadChart(sdk, chartId)
              .then(resolve)
              .catch(reject);
          });
        } else {
          resolve();
        }
      });
    },

    loadChart(sdk, chartId) {
      return new Promise((resolve, reject) => {
        const chart = sdk.createChart({
          getUserToken: () => storage.getToken(),
          showAttribution: false
        });

        const chartElement = document.getElementById(chartId);

        if (chartElement) {
          chart.render(chartElement)
            .then(() => {
              chartElement.sdkInstance = chart;
              resolve(); 
            })
            .catch((e) => {
              console.error(`[ClicMongoDBChart] Falha ao carregar 'chart'. Detalhes: ${e}`);
              reject(e);
            });
        } else {
          console.error(`[ClicMongoDBChart] Elemento DOM com ID ${chartId} não encontrado.`);
          reject();
        }
      });
    },

    applyFilters(filtroAdicional = {}) {
      let filtros = this.getFilters(filtroAdicional);
      if (!filtros){ return }

      this.chartElements.forEach(chart => {
        const chartElement = document.getElementById(chart.chartId);
        chartElement?.sdkInstance.setFilter(filtros).catch(e => {
          console.error(`[ClicMongoDBChart] Erro ao aplicar filtros: ${e}`);
        });
      });
    },
    getFilters(filtroAdicional) {
      const componentesFilhos = lodash.get(this.$refs, "rowFiltros.childrens[0].childrens[0].childrens[0].childrens", []);
      const comp = this.$getComponents()

      let filtros = {};

      for (const componente of componentesFilhos) {
        let jsonId = componente.props.jsonId
        let value = comp[componente.id].getPropValue("value")
        
        if (jsonId && value) {
          if (componente.filtroMongo?.regex) {
            let regex = componente.filtroMongo.regex
            let valorSubstituido = lodash.get(value, componente.filtroMongo.propriedade, value)
            value = JSON.parse(regex.replace("@VALUE@", valorSubstituido))
          }

          if (!filtros[jsonId]) {
            filtros[jsonId] = value
          } else {
            // Caso haja dois componentes filtrando o mesmo valor, como ocorre com datas
            filtros[jsonId] = lodash.merge({}, filtros[jsonId], value);
          }
        }
      }

      const retornoComFiltrosPadrao = lodash.merge({}, this.filtrosPadrao, filtros, filtroAdicional)
      return retornoComFiltrosPadrao
    },
    setFilters() {
      if (this.filters) {
        let childrenFiltro = []
        this.filters.forEach((filtro) => {
          childrenFiltro.push({ ...filtro.componente, filtroMongo: filtro.filtroMongo });
        });

        if(childrenFiltro.length > 0){
          this.filterElements = [{
            "id": "containerFiltros",
            "component": "ClicRow",
            "props": { "visible": true },
            "events": {},
            "childrens": [
              {
                "id": "collapseFiltros",
                "component": "ClicCollapse",
                "props": { "visible": true, "label": "Filtros" },
                "events": {},
                "childrens": [
                  {
                    "id": "rowFiltros",
                    "component": "ClicRow",
                    "props": { "visible": true },
                    "events": {},
                    "childrens": [
                      ...childrenFiltro,
                      {
                        "id": "buttonFiltrar",
                        "component": "ClicButton",
                        "props": { "visible": true, "label": "Filtrar" },
                        "events": {
                          "click": `let comp = this.$getComponents(); comp['${this.cp.id}'].applyFilters()`
                        },
                      }
                    ]
                  }
                ]
              }
            ]
          }]
        }
      }
    },
  },
};
</script>


<style>
.Charts_app-dashboard-shared_layout---mtgxT {
  padding: 0px !important;
}
</style>