<template>
  <div 
    v-show="visible" 
    class="display-contents"
  >
    <b-row 
      v-if="!isLoading" 
      :key="componentKey"
    >
      <b-col
        style="  margin-top: 10px !important;"
        :cols="cols"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
        :id="$utils.getComponentID(cp.id)"
        v-for="(row, index) in dadosCatalogo" 
        :key="index"
        class="p-1"
      >
        <b-row 
          class="catalog-card m-1"
          :style="style"
        >
          <MotorS 
            :cp="cp" 
            :childrens="childrens"
            :idTela="idTela"
            :state="{ row: {...row, index}, parent: cp}"
          />
        </b-row>
      </b-col>
    </b-row>

    <b-col 
      class="d-flex justify-content-start mt-3"
      v-if="!isLoading && !hidePagination"
    >
      <b 
        v-if="totalRegistrosPaginaAtual > 0"
        class="info-pagination"
      >
        Exibindo {{ ((paginaAtual-1) * itemsPerPage) +1 }} até {{ (paginaAtual * itemsPerPage) - (itemsPerPage - totalRegistrosPaginaAtual) }} de
        {{ totalRegistros }}
      </b>
    </b-col>
    
    <b-col 
      class="d-flex justify-content-center"
      v-if="!isLoading && !hidePagination"
    >
      <b-pagination
        v-model="paginaAtual"    
        :total-rows="totalRegistros"
        @page-click="mudarPagina"
        :per-page="itemsPerPage"
        :key="totalRegistros"
        class="pagination-custom"
      ></b-pagination>
    </b-col>

    <b-col 
      class="text-center h-25 justify-content-center" 
      align-v="center" 
      v-if="isLoading"
    >
      <b-spinner 
        class="align-middle"
      >
      </b-spinner>
    </b-col>

  </div>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name : 'ClicCatalog',
  mixins: [ComponentMixin],

  data(){
    return{
       public : {
        dataRefresh : this.popularDadosCatalogo,
      },
      dadosCatalogo : [],
      totalRegistros:0,
      totalRegistrosPaginaAtual:0,
      paginaAtual:1,
      componentKey : 0,
      isLoading: false,

      mouseOverActive: false,

    }
  },

  created(){
    if (this.configAxiosContent && !this.pathItemsCols){
      console.error("[ClicCatalog] É necessário informar a propriedade 'pathItemsCols' indicando o caminho para os itens do catalogo. \n\nEx: 'dados'")

    } else if(this.configAxiosContent && !this.pathItemsColsCounter){
      console.error("[ClicCatalog] É necessário informar a propriedade 'pathItemsColsCounter' indicando o caminho para com o total de itens. \n\nEx: 'totalCols'")
    };
  },

  async mounted(){
    this.popularDadosCatalogo();
  },

  methods:{
    async popularDadosCatalogo(){
      this.setPropValueAndCommit("rows", [])
      this.dadosCatalogo = []
      this.dadosCatalogo = await this.obterDadosTabela();
    },

    obterDadosTabela() {      
      return new Promise((resolve, reject) => {
        let cols = [];
        this.isLoading = true;
        
        if (this.jsonData && !this.configAxiosContent) {

          this.calcularTotalRegistrosPorPagina();

          cols = this.obterDadosPaginadosLadoCliente();
          this.isLoading = false;

          resolve(cols);

        } else if (this.configAxiosContent) {
          
          let axios = this.configAxiosContent;
          const tipoParametro = this.lodash.get(axios, 'method', 'GET').toUpperCase() == 'GET' ? 'params' : 'data';

          axios[tipoParametro].sortBy       = this?.sortBy;
          axios[tipoParametro].sortDescAsc  = !this.sortDesc ? 'ASC' : 'DESC';
          axios[tipoParametro].fetch        = this.itemsPerPage

          this.$axios(axios).then(response => {   
            const pathDados                 = (this.pathItemsCols && this.pathItemsCols.length) ? `data.${this.pathItemsCols}` : 'data';
            cols                            = this.lodash.get(response, pathDados, []);  
            const pathCount                 = (this.pathItemsColsCounter && this.pathItemsColsCounter.length) ? `data.${this.pathItemsColsCounter}` : 'data';
            this.totalRegistros             = this.lodash.get(response, pathCount, (cols.length || 0));  
            this.totalRegistrosPaginaAtual  = cols.length;                      

            this.isLoading = false;
            resolve(cols);
          });          
        }          
      });
    },

    async mudarPagina(page) {

      this.$nextTick(async ()=>{ // Aguarda o DOM <pagination> atualizar para então capturar a paginaAtual.
        this.paginaAtual = page?.vueTarget?.computedCurrentPage;

        if (this.paginaAtual) {

          if (this.configAxiosContent) {
            this.configAxiosContent.params.fetch  = this.itemsPerPage;
            let skip                              = (this.paginaAtual - 1) * this.itemsPerPage;

            if (skip != this.configAxiosContent.params.skip){
              this.configAxiosContent.params.skip = skip;          
            };

            this.dadosCatalogo = await this.obterDadosTabela();

          }else if(!this.configAxiosContent && this.jsonData && this.jsonData.length > 0){

            this.dadosCatalogo = this.obterDadosPaginadosLadoCliente()
          };

          this.atualizarCatalogo();
        };

      });
     
    },

    obterDadosPaginadosLadoCliente(){
      try{
        const indexInicial = ( this.paginaAtual - 1 ) * this.itemsPerPage;
        const indexFinal = indexInicial + this.itemsPerPage;

        return this.jsonData.slice(indexInicial, indexFinal);
      }catch(e){
        console.error("[ClicCatalog] Ocorreu uma falha ao obter os dados paginados do lado do cliente. Detalhes: ", e);
        return [];
      }
    },

    calcularTotalRegistrosPorPagina(){
      this.totalRegistros             = this.jsonData.length;
      this.totalRegistrosPaginaAtual  = ((this.paginaAtual) * (this.itemsPerPage)) < this.totalRegistros 
        ? ((this.paginaAtual) * (this.itemsPerPage)) : this.totalRegistros;

    },

    atualizarCatalogo(){
      this.isLoading = true;
      this.componentKey += 1;
      this.isLoading = false;
    }

  }

};
</script>

<style>
.catalog-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 5px rgba(0, 0, 0, 0.2);
  height:100%;
}

.pagination-custom .page-link {
  border-radius: 50px !important;
  margin-left: 10px !important;
  background-color: white !important;
}

</style>