import { io } from 'socket.io-client';
import TokenService from "./tokenService";


class SocketServerService {
  socketServer;

  constructor() {
    this.connectDefault();
  }

  connectDefault() {
    try {
      const userData = TokenService.getUser();
      const token = userData && userData.accessToken;
      this.socketServer = io(`${process.env.VUE_APP_SOCKET_ENDPOINT}/serversocket`,
        {
          transports: ['websocket'],
          path: `/socket/server/`,
          auth: {
            token
          }
        }
      );
    } catch(e) {
      console.error(`[socket] falha ao criar e configurar a conexão socketserver. Detalhes: `, e);
    } 
  }
  
  disconnect() {
    if(this.socketServer) {
      this.socketServer.disconnect();
    }
  }
  
}

export default new SocketServerService();