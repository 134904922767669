const XLSX = require('xlsx');
const lodash = require('lodash');

const converterJsonParaXls = ({ jsonData, fields, fileName, sheetName, bookType, fs }) => {
    const defaultError = "Falha em 'converterJsonParaXls': ";
    if (!fileName) { throw new Error(`${defaultError} 'filename' não informado.`) };
    if (!jsonData) { throw new Error(`${defaultError} 'jsonData' não informado.`) };
    if (!Array.isArray(jsonData)) { throw new Error(`${defaultError} 'jsonData' não é um array.`) };

    fileName = `${fileName}.${bookType || "csv"}`;
    const mappedData = mapearCamposJsonParaColunasExcel({ jsonData, fields });

    // Criar uma planilha
    const ws = XLSX.utils.json_to_sheet(mappedData);

    // Criar um workbook e adicionar a planilha
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet1');

    // Gerar o arquivo CSV corretamente
    const wbout = XLSX.write(wb, { bookType: bookType || 'csv', type: 'string', FS: fs || ';' });

    // Converter para UTF-8 corretamente
    const encoder = new TextEncoder();
    const encodedBuffer = encoder.encode("\uFEFF" + wbout); // Adiciona BOM UTF-8

    // Codificar em base64 para uso no React Native
    const b64Excel = btoa(String.fromCharCode(...encodedBuffer));

    // Compartilhar o arquivo
    if (!isWebView()) {
        // Ambiente web: baixar o arquivo
        const blob = new Blob([encodedBuffer], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    if (window.ReactNativeWebView) {
        // Ambiente React Native: enviar o arquivo para o WebView
        const excelInformation = {
            type: "fileShare",
            params: {
                content: b64Excel,
                filename: fileName,
                mimeType: "text/csv"
            }
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(excelInformation));
    }
}

const mapearCamposJsonParaColunasExcel = ({ jsonData, fields }) => {
    return jsonData.map(item => {
        const mappedItem = {};
        for (const field in fields) {
            const excelColumnName = fields[field];
            mappedItem[excelColumnName] = lodash.get(item, field);
        }
        return mappedItem;
    });
}

const isWebView = () => {
    return window.isWebView;
}

module.exports = {
    converterJsonParaXls
}