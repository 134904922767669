
import Vue from 'vue';
import App from './App.vue';
import router from './router/Router.js';
import './registerServiceWorker';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, { $axios : axios });

import 'es6-promise/auto';

import VueLodash from 'vue-lodash';
import lodash from 'lodash';
Vue.use(VueLodash, { lodash: lodash });

import utils from './core/utils';
Vue.prototype.$utils = utils;
import pdfUtils from './core/pdfUtils';
Vue.prototype.$pdfUtils = pdfUtils;
import xlsUtils from './core/xlsUtils';
Vue.prototype.$xlsUtils = xlsUtils;

import { EventBus } from './core/eventBus';
Vue.prototype.$eventBus = EventBus;

Vue.prototype.$state = {};

import store from './store/index';

import storage from './store/modules/storage';
Vue.prototype.$storage = storage;

import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store, axios);

Vue.prototype.$getPropriedades = (_this) => {
  return _this.$store.getters[`${_this.idTela}/getPropriedades`];
}

import { OhVueIcon, addIcons } from "oh-vue-icons";
import * as FaIcons from "oh-vue-icons/icons/fa";
import * as MdIcons from "oh-vue-icons/icons/md";
import * as BiIcons from "oh-vue-icons/icons/bi";
const Fa = Object.values({ ...FaIcons });
const Md = Object.values({ ...MdIcons });
const Bi = Object.values({ ...BiIcons });
addIcons(...Fa, ...Md, ...Bi);
Vue.component("v-icon", OhVueIcon);

import ClicIcon from './components/clicIcon/ClicIcon.vue';
Vue.component('ClicIcon', ClicIcon);

import ClicIconInterno from './components/clicIcon/ClicIconInterno.vue';
Vue.component('ClicIconInterno', ClicIconInterno);

import ClicHintInterno from './components/clicHint/ClicHintInterno.vue';
Vue.component('ClicHintInterno', ClicHintInterno);

import ClicBadgeInterno from './components/clicBadge/ClicBadgeInterno.vue';
Vue.component('ClicBadgeInterno', ClicBadgeInterno);

import MotorS from './views/MotorS.vue';
Vue.component('MotorS', MotorS);

import Main from './views/Main.vue';
Vue.component('Main', Main);

import ClicButton from './components/clicButton/ClicButton.vue';
Vue.component('ClicButton', ClicButton);

import ClicCheckbox from './components/clicCheckbox/ClicCheckbox.vue';
Vue.component('ClicCheckbox', ClicCheckbox);

import ClicDatePicker from './components/clicDatePicker/ClicDatePicker.vue';
Vue.component('ClicDatePicker', ClicDatePicker);

import ClicInput from './components/clicInput/ClicInput.vue';
Vue.component('ClicInput', ClicInput);

import ClicInputFile from './components/clicInputFile/ClicInputFile.vue';
Vue.component('ClicInputFile', ClicInputFile);

import ClicLink from './components/clicLink/ClicLink.vue';
Vue.component('ClicLink', ClicLink);

import ClicInputMask from './components/clicInput/clicInputMask/ClicInputMask.vue';
Vue.component('ClicInputMask', ClicInputMask);

import ClicInputCurrencyMask from './components/clicInput/clicInputCurrencyMask/ClicInputCurrencyMask.vue';
Vue.component('ClicInputCurrencyMask', ClicInputCurrencyMask);

import ClicProgressBar from './components/clicProgressBar/ClicProgressBar.vue';
Vue.component('ClicProgressBar', ClicProgressBar);

import ClicSwitch from './components/clicSwitch/ClicSwitch';
Vue.component('ClicSwitch', ClicSwitch);

import ClicMultiselect from './components/clicMultiselect/ClicMultiselect';
Vue.component('ClicMultiselect', ClicMultiselect);

import ClicCodeEditor from './components/clicCodeEditor/ClicCodeEditor.vue';
Vue.component('ClicCodeEditor', ClicCodeEditor);

import ClicNotification from './components/clicNotificacao/ClicNotification.vue';
Vue.component('ClicNotification', ClicNotification);

import ClicNotificationInterno from './components/clicNotificacao/ClicNotificationInterno.vue';
Vue.component('ClicNotificationInterno', ClicNotificationInterno);

import ClicTabs from './components/clicTabs/ClicTabs.vue';
Vue.component('ClicTabs', ClicTabs);

import ClicTab from './components/clicTabs/ClicTab.vue';
Vue.component('ClicTab', ClicTab);

import ClicTreeView from './components/clicTreeView/ClicTreeView.vue';
Vue.component('ClicTreeView', ClicTreeView);

import ClicWebCam from './components/clicWebCam/ClicWebCam.vue';
Vue.component('ClicWebCam', ClicWebCam);

import ClicGeolocation from './components/clicGeolocation/ClicGeolocation.vue';
Vue.component('ClicGeolocation', ClicGeolocation);

import ClicNavBar from './components/clicNavBar/ClicNavBar.vue';
Vue.component('ClicNavBar', ClicNavBar);

import ClicNavBarBrand from './components/clicNavBar/ClicNavBarBrand.vue';
Vue.component('ClicNavBarBrand', ClicNavBarBrand);

import ClicNavBarNav from './components/clicNavBar/ClicNavBarNav.vue';
Vue.component('ClicNavBarNav', ClicNavBarNav);

import ClicDropdown from './components/clicDropdown/ClicDropdown.vue';
Vue.component('ClicDropdown', ClicDropdown);

import ClicDropdownDivider from './components/clicDropdown/ClicDropdownDivider.vue';
Vue.component('ClicDropdownDivider', ClicDropdownDivider);

import ClicDropdownItem from './components/clicDropdown/ClicDropdownItem.vue';
Vue.component('ClicDropdownItem', ClicDropdownItem);

import ClicCard from './components/clicCard/ClicCard.vue';
Vue.component('ClicCard', ClicCard);

import ClicImage from './components/clicImage/ClicImage.vue';
Vue.component('ClicImage', ClicImage);

import ClicImageInterno from './components/clicImage/ClicImageInterno.vue'
Vue.component('ClicImageInterno', ClicImageInterno);

import ClicRow from './components/clicGridLayout/ClicRow.vue';
Vue.component('ClicRow', ClicRow);

import ClicCol from './components/clicGridLayout/ClicCol.vue';
Vue.component('ClicCol', ClicCol);

import ClicColInterno from './components/clicGridLayout/ClicColInterno.vue';
Vue.component('ClicColInterno', ClicColInterno);

import ClicTable from './components/clicTable/ClicTable.vue';
Vue.component('ClicTable', ClicTable);
import ClicTableColumnText from './components/clicTable/ClicTableColumnText.vue';
Vue.component('ClicTableColumnText', ClicTableColumnText);
import ClicTableColumnCustom from './components/clicTable/ClicTableColumnCustom.vue';
Vue.component('ClicTableColumnCustom', ClicTableColumnCustom);
import ClicTableColumnDetail from './components/clicTable/ClicTableColumnDetail.vue';
Vue.component('ClicTableColumnDetail', ClicTableColumnDetail);

import ClicModal from './components/clicModal/ClicModal.vue';
Vue.component('ClicModal', ClicModal);

import ClicModalFooter from './components/clicModal/ClicModalFooter.vue';
Vue.component('ClicModalFooter', ClicModalFooter);

import ClicHint from './components/clicHint/ClicHint.vue';
Vue.component('ClicHint', ClicHint);

import ClicJson from './components/clicJson/ClicJson.vue';
Vue.component('ClicJson', ClicJson);

import ClicText from './components/clicText/ClicText.vue';
Vue.component('ClicText', ClicText);

import ClicIFrame from './components/clicIFrame/ClicIFrame.vue';
Vue.component('ClicIFrame', ClicIFrame);

import ClicToolbar from './components/clicToolbar/ClicToolbar.vue';
Vue.component('ClicToolbar', ClicToolbar);

import ClicList from './components/clicList/ClicList.vue';
Vue.component('ClicList', ClicList);

import ClicTextArea from './components/clicTextArea/ClicTextArea.vue';
Vue.component('ClicTextArea', ClicTextArea);

import ClicChart from './components/clicChart/ClicChart.vue';
Vue.component('ClicChart', ClicChart);

import ClicMarkdown from './components/clicMarkdown/ClicMarkdown.vue';
Vue.component('ClicMarkdown', ClicMarkdown);

import ClicSwagger from './components/clicSwagger/ClicSwagger.vue';
Vue.component('ClicSwagger', ClicSwagger);

import Pedidov2 from './components/framework/Pedidov2.vue';
Vue.component('Pedidov2', Pedidov2);

import ClicCopilot from './components/clicCopilot/ClicCopilot.vue';
Vue.component('ClicCopilot', ClicCopilot);

import ClicSession from './components/clicSession/ClicSession.vue';
Vue.component('ClicSession', ClicSession);

import ClicCatalog from './components/clicCatalog/ClicCatalog.vue';
Vue.component('ClicCatalog', ClicCatalog);

import ClicCollapse from './components/clicCollapse/ClicCollapse.vue';
Vue.component('ClicCollapse', ClicCollapse);

import ClicInputGroup from './components/clicInputGroup/ClicInputGroup.vue';
Vue.component('ClicInputGroup', ClicInputGroup);

import ClicCalendar from './components/clicCalendar/ClicCalendar.vue';
Vue.component('ClicCalendar', ClicCalendar);

import ClicCronBuilder from './components/clicCronBuilder/ClicCronBuilder.vue';
Vue.component('ClicCronBuilder', ClicCronBuilder);

import ClicBreadcrumb from './components/clicBreadcrumb/ClicBreadcrumb.vue';
Vue.component('ClicBreadcrumb', ClicBreadcrumb);

import ClicCodeReader from './components/clicCodeReader/ClicCodeReader.vue';
Vue.component('ClicCodeReader', ClicCodeReader);

import ClicCodeDiff from './components/clicCodeDiff/ClicCodeDiff.vue';
Vue.component('ClicCodeDiff', ClicCodeDiff);

import ClicMongoDBChart from './components/clicMongoDBChart/ClicMongoDBChart.vue';
Vue.component('ClicMongoDBChart', ClicMongoDBChart);


import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/theme/css/main.css'
import './assets/theme/css/custom-theme.css'
Vue.use(BootstrapVue);
//Vue.use(IconsPlugin);

import Notifications from 'vue-notification';
Vue.use(Notifications, { name: 'notificacao' });

import VueGeolocationApi from 'vue-geolocation-api';
Vue.use(VueGeolocationApi);

import CriadorTela from './components/framework/CriadorTela.vue';
Vue.component('CriadorTela', CriadorTela);

//import IntegrationBuilder from './components/framework/hub/IntegrationBuilder.vue';
//Vue.component('IntegrationBuilder', IntegrationBuilder);


Vue.config.productionTip = false;
Vue.config.disableHostCheck = true;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');


