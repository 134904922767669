<template>
  <ClicColInterno :cp="cp" :key="componentKey">
    <b-breadcrumb :items="items" :class="card ? 'breadcrumb-card-container' : ''" @click="click"></b-breadcrumb>
  </ClicColInterno>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicBreadcrumb',
  mixins: [ComponentMixin],
  data() {
    return {
      public: {
        getItemsToString: this.getItemsToString,
        setStringItems: this.setStringItems
      },
      items: []
    
    };
  },
  created() {
    this.verifyIsRoute()
  },
  methods: {
    click($event) {
      if (this.lodash.has(this.cp, 'events.click')) {
        try {
          eval(this.cp.events.click);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'click'. Detalhes: ${e}`);
        }
      }
    },
    verifyIsRoute() {
      if (this.representRoute) {
        const { hostname, protocolo } = this.$host
        let routeHistory = this.$routeHistory || []
        let items = []

        for (let route of routeHistory) {
          items.push({
            text: route.to.replace(/\//g, ""),
            href: route.to
          })

        }

        this.items = items

      }
    },
    getItemsToString() {
      let itemsString = ""
      for (let item of this.items) {
        itemsString += `${item.text}/`
      }
      return itemsString
    },
    setStringItems(stringItems) {
      const itemsParts = stringItems.split('/')
      let items = []

      for (let part of itemsParts) {
        if (part != "") {
          items.push({
            text: part,
            href: ""
          })
        }
      }

      this.items = items
    },
  }
}
</script>
<style scoped>
.breadcrumb-card-container {
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}
</style>
