<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <div class="form-group left-label">    
      <div class="d-flex flex-row align-items-start">
          <label class="form-label form-label-clic" v-if="label">
           <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
            {{ $storage.getTextoTraduzido(label) }}
          </label>
          <ClicHintInterno
              v-if="hintText"
              :cp="cp"
          />
      </div>

      <div class="form-control-wrap">
        <div class="form-icon form-icon-left" v-if="iconName">
          <ClicIconInterno
            v-if="iconName"
            :cp="cp"
          />
        </div>
        
        <the-mask 
          v-model="value" 
          :mask="mask"
          :disabled="disabled"
          :class="`form-control ${this.class}`"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="readonly"
          :required="required"
          :masked="masked"
          :placeholder="$storage.getTextoTraduzido(placeholder)"
          :type="type"
          :tokens="tokens"
          @change.native="change"
          @input="input"
          @blur.native="blur"
          v-on:keyup.native="keyup"
          :style="fieldState === false ? 'border-color:red' : style"
        />
        <h5 
          v-if="fieldState === false && invalidFeedbackMessage" 
          id="input-live-feedback" 
          class="text-danger invalid-feedback-message"
        >
          {{invalidFeedbackMessage}}
        </h5>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../../core/ComponentMixin';
import { TheMask } from 'vue-the-mask'

export default {
  name: 'ClicInputMask',
  mixins: [ComponentMixin],
  components: {TheMask},
  data() {
    return {
      public:{
        isValid     : this.validateField,
        clearField  : this.clearField,

        undo            : this.undo,
        apply           : this.apply,
        isValueChanged  : this.isValueChanged,
      },

      value      : undefined,
      oldValue   : undefined,
      fieldState : undefined,
    }
  },

  mounted() {
    this.oldValue = this.value;
  },

  methods: {

    input(value) {
      if(!this.fieldState || this.fieldState){
        this.fieldState = undefined;
      };
      if(this.lodash.has(this.cp, 'events.input')){
        try {
          eval(this.cp.events.input);
        } catch (e) {
          console.error(`[ClicInputMask] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    change(value) {
      if(this.lodash.has(this.cp, 'events.change')){
        try {
          eval(this.cp.events.change);
        } catch (e) {
          console.error(`[ClicInputMask] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    blur(event) {
      if (this.lodash.has(this.cp, 'props.validateScript')){
        this.validateField();  
      };

      if  (this.lodash.has(this.cp, 'events.blur')) {
        try {
          eval(this.cp.events.blur);
        } catch (e) {
          console.error(`[ClicInputMask] Falha ao executar evento 'blur'. Detalhes: ${e}`);
        }
      }
    },
    keyup(keyboardEvent) {
      if  (this.lodash.has(this.cp, 'events.keyup')) {
        try {
          eval(this.cp.events.keyup);
        } catch (e) {
          console.error(`[ClicInputMask] Falha ao executar evento 'keyup'. Detalhes: ${e}`);
        }
      }
    },

    undo() {
      this.value = this.oldValue;
    },
    apply() {
      this.oldValue = this.value;
    },
    isValueChanged() {
      return this.oldValue != this.value;
    },
  },

};
</script>

<style>
.invalid-feedback-message {
  padding-top:4px;
  font-size: 11.2px;
  font-weight: 100;
}
</style>