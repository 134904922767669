import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        filtrosPadrao          :{
            hintTitle: "",
            hintText:"Filtros que serão enviados sempre a menos que haja algum componente no collapse que substitua o valor da chave."
        },
        filtrosIniciais          :{
            hintTitle: "",
            hintText:"Filtros que serão enviados ao inicializar o dashboard."
        },
    }
}









