import AuthService from '../../services/authService';
import storage from '../../store/modules/storage';
import router from '../../router/Router';

const user = JSON.parse(localStorage.getItem(storage.getSessionKey()));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, dadosLogin) {
      return AuthService.login(dadosLogin).then(
        login => {
          delete login.refreshToken;
          commit('loginSuccess', login);
          return Promise.resolve(login);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error.response.data || error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');

      const postMessage = {
        type : "userAction",
        params : { 
          action : "logout"
        }
      };
      this.window?.ReactNativeWebView?.postMessage(JSON.stringify(postMessage));

      router.go();
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken };
    }
  }
};