<template>

  <ClicColInterno :cp="cp">
    <b-row v-if="controlsTopMenu" class="justify-content-end">
      <b-col sm="5" md="5" class="text-right justify-content-end">
        <div class="form-group left-label">
          <div class="form-control-wrap">
            <b-form-input autocomplete="off" id="input-sm" v-model="filter" placeholder="localizar..." v-on:keyup="(filter.length == 0) ||
    configAxiosContent && configAxiosContent.params &&
    configAxiosContent.params.filter && configAxiosContent.params.filter.length >
    filter.length ? filterRows('', filter) : null"></b-form-input>
            <div class="form-icon form-icon-right">
              <b-link @click="clearFilterSearch" class="p-0">
                <v-icon :name="filter ? 'md-close-round' : 'md-search'" scale=1 :hover="!isLoading" />
              </b-link>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="2" md="1" class="text-center justify-content-end">
        <b-link @click="atualizarConsulta">
          <v-icon name="bi-arrow-clockwise" scale=1.5 :hover="!isLoading" animation="spin" />
        </b-link>
      </b-col>

    </b-row>

    <b-row style="padding-top:5px;">
      <b-col cols="12" class="">
        <b-table :id="$utils.getComponentID(cp.id)" 
        ref="customTable" 
        :per-page="itemsPerPage"
        :current-page="this.configAxiosContent ? undefined : currentPage" 
        :filter="filter"
        :filter-function="configAxiosContent && configAxiosContent.params.filter != filter ? filterRows : null"
        :filter-debounce="800" 
        :fields="cabecalhosTabela" 
        :items="dataRow" 
        :busy="isLoading" 
        :sort-by="sortBy"
        :sort-desc="sortDesc" 
        :stacked="stacked" 
        :borderless="borderless" 
        :hover="true" 
        :striped="false"
        :outlined="outlined" 
        :dark="dark" 
        :footClone="footClone" 
        :small="small" 
        @row-clicked="rowClickHandler" @sort-changed="handleSortChange" @row-selected="selecionarLinha" responsive
        table-class="datatable-init clic-tb-list clic-tb-ulist dataTable no-footer custom-table"
        tbody-class="bg-table" 
        :tbody-tr-class="rowClass != undefined ? rowClass : ''"
        thead-class="rounded-header bg-white" thead-tr-class="clic-tb-item clic-tb-head" 
        :selectable="selectable"
        :select-mode="selectMode" 
        :no-select-on-click="noSelectOnClick">

          <template #head(selected)="row">
            <b-col class="p-0">
              <b-form-checkbox class="select-col" size="xl"
                @change="row.rowSelected ? selecionarTodasLinhas() : limparLinhasSelecionadas()"
                v-model="row.rowSelected">
              </b-form-checkbox>
            </b-col>
          </template>

          <template #cell(selected)="row">
            <b-col class="p-0">
              <b-form-checkbox class="select-col" size="xl" @change="rowChecked(row)" v-model="row.rowSelected">
              </b-form-checkbox>
            </b-col>
          </template>

          <template #cell()="row" v-if="cabecalhosTabela && cabecalhosTabela.length > 0">
            <div style="display: contents; border-radius: 100px;">
              <MotorS :cp="row.field" :childrens="[row.field]" :idTela="idTela" :state="{ row, parent: cp }"
                :key="$utils.getComponentID()" />
            </div>
          </template>

          <template #row-details="row">
            <div style="display:contents">
              <MotorS :cp="getClicTableColumnsDetailFromChildrens()"
                :childrens="[getClicTableColumnsDetailFromChildrens()]" :idTela="idTela" :state="{ row, parent: cp }"
                :key="$utils.getComponentID()" />

            </div>
          </template>

          <template #table-busy>
            <strong style="margin-left:10px">Carregando...</strong>
            <div class="text-center my-2">
              <b-spinner class="align-middle"> </b-spinner>
            </div>
          </template>
        </b-table>
        <strong v-if="this.dataRow && this.dataRow.length === 0 && !this.isLoading" style="margin-left:10px">Nenhum
          registro foi
          encontrado</strong>
      </b-col>
    </b-row>


    <b-row v-if="!this.isLoading && !this.hidePagination" class="align-items-center">
      <b-col class="d-flex p-2 justify-content-start">
        <b v-if="totalRegistroscurrentPage > 0" class="info-pagination">
          Exibindo {{ ((currentPage - 1) * itemsPerPage) + 1 }} até {{ (currentPage * itemsPerPage) - (itemsPerPage -
    totalRegistroscurrentPage) }} de
          {{ totalRegistros }}
        </b>
      </b-col>

      <b-col class="d-flex p-2 justify-content-center">
        <b-pagination v-model="currentPage" :total-rows="totalRegistros" @page-click="handlePageChange"
          :per-page="itemsPerPage" class="pagination-custom"></b-pagination>
      </b-col>

      <b-col class="d-flex p-2 justify-content-end">
        <b-form-select class="text-center per-page-select" v-model="itemsPerPage" :options="pageOptions"
          v-on:change="handleItemsPerPageChange" v-if="hasPerPageSelect" />
      </b-col>
    </b-row>
  </ClicColInterno>

</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicTable',
  mixins: [ComponentMixin],

  data() {
    return {
      public: {
        getRowContent: this.rowClickHandler,
        rowClickHandler: this.rowClickHandler,
        dataRefresh: this.atualizarConsulta,
        $toggleRowDetails: this.$toggleRowDetails,
        rows: [],
        getSelectedRows: this.obterLinhasSelecionadas,
        selectAllRows: this.selecionarTodasLinhas,
        clearSelected: this.limparLinhasSelecionadas,
        headersRefresh: this.atualizarCabecalhoExistente,
        toggleColumnVisibility: this.alterarVisibilidadeColuna,
        append: this.append,
        push: this.push,
        remove: this.remove
      },
      dataRow: [],

      isLoading: true,
      cabecalhosTabela: [],
      totalRegistros: 0,
      totalRegistroscurrentPage: 0,
      currentPage: 1,
      lockFilter: false,
      interval: null,
      linhasSelecionadas: [],

      customColumns: [],
      skip: 0,

      filter: '',
      pageOptions: [
        { value: 1, text: '1' },
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
        { value: 20, text: '20' },
        { value: 25, text: '25' }
      ]
    };
  },
  created() {

    if (this.configAxiosContent && !this.pathItemsRows) {
      console.error("[ClicTable] É necessário informar a propriedade 'pathItemsRows' indicando o caminho para os itens da tabela. \n\nEx: 'dados'")

    } else if (this.configAxiosHeaders && !this.pathItemsHeaders) {
      console.error("[ClicTable] É necessário informar a propriedade 'pathItemsHeaders' indicando o caminho para os headers da tabela. \n\nEx: 'headers'")

    } else if (this.configAxiosContent && !this.pathItemsRowsCounter) {
      console.error("[ClicTable] É necessário informar a propriedade 'pathItemsRowsCounter' indicando o caminho para com o total de itens. \n\nEx: 'totalRows'")

    } else if (!this.configAxiosContent && !this.dataRow) {
      this.isLoading = false;
    } else {
      this.atualizarConsulta();
    }

  },

  mounted() {
    if (this.lodash.has(this.cp, 'props.refreshIntervalSeconds')) {
      this.interval = setInterval(() => {
        this.atualizarConsulta()
      }, this.refreshIntervalSeconds * 1000)
    }

  },

  methods: {
    rowChecked(row) {
      const { index, rowSelected } = row;

      if (rowSelected === true) {
        this.$refs.customTable.selectRow(index);
      } else {
        this.$refs.customTable.unselectRow(index);
      }
    },

    obterLinhasSelecionadas() {
      return this.linhasSelecionadas;
    },

    selecionarLinha(rows) {
      try {
        this.linhasSelecionadas = rows;

        if (this.lodash.has(this.cp, 'events.rowSelected')) {
          eval(this.cp.events.rowSelected);
        };
      } catch (e) {
        console.error(`[ClicTable] Falha ao executar evendo 'rowSelected'. Detalhes: ${e}`);
      }
    },

    selecionarTodasLinhas() {
      this.$refs.customTable.selectAllRows()
    },

    limparLinhasSelecionadas() {
      this.$refs.customTable.clearSelected()
    },

    adicionarCabecalhoColunaSelecionavel() {
      if (this.selectable) {
        this.cabecalhosTabela.unshift({
          key: 'selected',
          label: "",
          thStyle: "width:0; padding-left:0.2em; border: none;",
          variant: 'light',
          class: "text-right",
          stickyColumn: true,
        })
      }
    },

    atualizarCabecalhoExistente() {
      this.atualizarConsulta("headers");
    },

    append(item) {
      if (Array.isArray(item)) {
        this.dataRow.unshift(...item); 
      } else {
        this.dataRow.unshift(item); 
      }
      this.setPropValueAndCommit("rows", [])
    },
    push(item) {
      if (Array.isArray(item)) {
        this.dataRow.push(...item); 
      } else {
        this.dataRow.push(item); 
      }
      this.setPropValueAndCommit("rows", [])
    },
    remove(item, prop) {
      if (Array.isArray(item)) {
        item.forEach(i => {
          const index = this.dataRow.findIndex(el => el[prop] === i[prop]);
          if (index !== -1)  {
            this.dataRowthis.dataRow.splice(index, 1);
            this.setPropValueAndCommit("rows", [])
          }
        });
      } else {
        const index = this.dataRow.findIndex(el => el[prop] === item[prop]);
        if (index !== -1) {
          this.dataRow.splice(index, 1);
          this.setPropValueAndCommit("rows", [])
        }
      }
    },
    async atualizarConsulta(modo) {
      try {

        this.isLoading = true;
        this.setPropValueAndCommit("rows", [])
        
        if (modo && modo == "headers") {
          this.cabecalhosTabela = await this.obterCabecalhosTabela(modo);
        } else {
          this.dataRow = await this.obterDadosTabela();
          this.cabecalhosTabela = await this.obterCabecalhosTabela();
        };

        this.customColumns = this.cabecalhosTabela.filter(item => item.columnType === 'custom');

        this.adicionarCabecalhoColunaSelecionavel();

      } catch (e) {
        console.error(`[ClicTable] Falha ao atualizar a consulta. Detalhes: ${e}`);
      } finally {
        this.isLoading = false;
      }
    },

    calcularTotalRegistrosPorPagina() {
      this.totalRegistros = this.dataRow.length;
      this.totalRegistroscurrentPage = ((this.currentPage) * (this.itemsPerPage)) < this.totalRegistros
        ? ((this.currentPage) * (this.itemsPerPage)) : this.totalRegistros;
    },

    obterDadosTabela() {
      return new Promise((resolve, reject) => {
        let rows = [];

        if (this.dataRow && !this.configAxiosContent) {
          rows = this.obterDadosTabelaPadronizado(this.dataRow);
          this.calcularTotalRegistrosPorPagina();

          resolve(rows);

        } else if (this.configAxiosContent) {
          let axios = this.configAxiosContent;
          const tipoParametro = this.lodash.get(axios, 'method', 'GET') == 'GET' ? 'params' : 'data';
          axios[tipoParametro].sortBy = this.sortBy;
          axios[tipoParametro].sortDescAsc = !this.sortDesc ? 'ASC' : 'DESC';
          axios[tipoParametro].keysFilter = this.keysFilter;

          this.$axios(axios).then(response => {
            const pathDados = (this.pathItemsRows && this.pathItemsRows.length) ? `data.${this.pathItemsRows}` : 'data';
            rows = this.obterDadosTabelaPadronizado(this.lodash.get(response, pathDados, []));
            const pathCount = (this.pathItemsRowsCounter && this.pathItemsRowsCounter.length) ? `data.${this.pathItemsRowsCounter}` : 'data';
            this.totalRegistros = this.lodash.get(response, pathCount, (rows.length || 0));
            this.totalRegistroscurrentPage = rows.length;

            resolve(rows);
          });
        }
      });
    },

    obterDadosTabelaPadronizado(dadosTabela) {
      // return dadosTabela = dadosTabela.map(item => ({...item, isEdit: false, _showDetails: false}));
      // return dadosTabela = dadosTabela.map(item => ({...item, isEdit: false}));
      return dadosTabela = dadosTabela.map(item => ({ ...item }));
    },

    converterCabecalhosTabela(headers) {
      let retorno = [];

      headers.map((item) => {
        let component = item.columnType == "custom" ? "ClicTableColumnCustom" : "ClicTableColumnText";

        retorno.push(
          {
            id: this.lodash.get(item, "key", ""),
            component: component,
            childrens: this.lodash.get(item, "childrens", []),
            visible: this.lodash.get(item, "visible", true),
            key: this.lodash.get(item, "key", ""),
            label: this.lodash.get(item, "label", ""),
            class: !item.visible ? `d-none` : ``
          }
        );

      });

      return retorno;
    },

    converterLinhaDetalhesHeaders(headers) {
      const colunaDetalhes = headers.find((col) => col.columnType === "details");

      return colunaDetalhes ? [{ childrens: [{ ...colunaDetalhes, component: "ClicTableColumnDetail" }] }] : [];
    },

    obterCabecalhosTabela(modo) {
      return new Promise(async (resolve, reject) => {
        let headers = undefined;
        if (this.cabecalhosTabela && this.cabecalhosTabela.length && !modo) {
          resolve(this.cabecalhosTabela);
          return;

        } else {
          if (this.getClicTableHeaderFromChildrens().length) {
            // Primeira tentativa = pegar headers dos childrens do componente (usando ClicTableHeader + ClicTableColumn)
            const headersFromChildrens = this.getClicTableColumnsFromChildrens();
            headers = [];
            for (let col of headersFromChildrens) {
              headers.push(
                {
                  ...col,
                  ...col.props,
                  label: this.$storage.getTextoTraduzido(col.props?.label ?? ""),
                  key: this.$utils.getComponentID(col.props.key),
                  class: !col.props.visible ? `d-none` : `${col.props.class}`
                }
              );
            }

          } else if (this.headers && this.headers.length) {
            // Segunda tentativa = pegar headers das props do componente (json) e converte para o formato de estrutura
            headers = this.converterCabecalhosTabela(this.headers);

          } else if (this.dataRow && !this.configAxiosContent &&
            this.lodash.has(this.dataRow, this.pathItemsHeaders)) {
            // Terceira tentativa = pegar headers da própria requisição dos dados da table
            headers = this.lodash.get(this.dataRow, this.pathItemsHeaders, []);

          } else if (this.configAxiosHeaders) {
            // Quarta tentativa = pegar os headers através de outra requisição
            const headersReqHttp = await obterHeadersRequisicaoHttp({ axios: this.configAxiosHeaders, pathItemsHeaders: this.pathItemsHeaders });
            headers = this.converterCabecalhosTabela(headersReqHttp);

          } else {
            // Sem headers definidos, retorna vazio e monta o header com o nome dos próprios atributos
            headers = [];
          }
        }
        const headersMontados = this.montarHeaders(headers);
        resolve(headersMontados);
      });
    },

    alterarVisibilidadeColuna(idColuna, valor){
      let headers = this.getClicTableColumnsFromChildrens();
      for(let headerColumn of headers){
        if(headerColumn.id == idColuna){
          headerColumn.props.visible = valor
        }
      }
      this.atualizarCabecalhoExistente()
    },

    obterHeadersRequisicaoHttp(parametros) {
      return new Promise((resolve, reject) => {
        const { axios, pathItemsHeaders } = parametros;
        this.$axios(axios).then(response => {
          const pathDados = (pathItemsHeaders && pathItemsHeaders.length) ? `data.${pathItemsHeaders}` : 'data';
          resolve(this.lodash.get(response, pathDados, []));
        });
      });
    },

    montarHeaders(headers) {
      let formatedHeaders = [];
      let index = 0;
      headers.map((data) => {
        try {
          formatedHeaders.push({
            ...data,
            index: index++,
            formatter: data?.customFormatter ? this.customFormatterValidator(data.customFormatter, data.key) : "",
          });

        } catch (e) {
          console.error(`[ClicTable] Falha ao executar o evento 'buildHeaders' na célula '${data.key}'. \n\n Detalhes: ${e}`);
        }
      })
      return formatedHeaders;
    },

    customFormatterValidator(string, key) {
      let script = `(value, key, item) => {return ${string}}`;
      try {
        return eval(script);
      } catch (e) {
        console.error(`[ClicTable] Verifique a sintaxe utilizada no campo 'customFormatter' para a célula '${key}'. \n\n Detalhes: ${e}`);
      }
    },

    handleSortChange(context) {
      if (this.configAxiosContent) {
        this.sortBy = context.sortBy;
        this.sortDesc = context.sortDesc;
        this.atualizarConsulta();
      };
    },

    handlePageChange(_, page) {
      this.currentPage = page
      if (this.configAxiosContent) {
        this.configAxiosContent.params.fetch = this.itemsPerPage;
        let skip = (this.currentPage - 1) * this.itemsPerPage;
        if (skip != this.configAxiosContent.params.skip) {
          this.configAxiosContent.params.skip = skip;
        }
        this.atualizarConsulta();
      }
    },

    handleItemsPerPageChange(val) {
      this.itemsPerPage = val;
      if (this.configAxiosContent) {
        this.configAxiosContent.params.fetch = this.itemsPerPage;
      }
      this.atualizarConsulta();
    },

    clearFilterSearch() {
      this.filter = '';
      if (this.configAxiosContent) {
        this.configAxiosContent.params.filter = '';
        this.configAxiosContent.params.keyFilter = '';
        this.atualizarConsulta();
      }
    },

    rowClass(item, type) {
      let action = this.lodash.get(this.cp, 'events.rowClass', "");
      let retorno = '';//"clic-tb-item ";

      if (!item || type !== 'row') {
        return retorno;
      } else {
        retorno += action != "" ? eval(action) : "";
      }
      return retorno;
    },

    filterRows(filtered, filter) {
      if (!this.lockFilter && this.configAxiosContent) {

        this.lockFilter = true;

        setTimeout(() => {
          let oldFilter = this.configAxiosContent.params.filter ? this.configAxiosContent.params.filter : filter;
          let oldActiveFilter = this.configAxiosContent.params.filter;

          this.configAxiosContent.params.filter = this.filter;
          this.configAxiosContent.params.skip = 0;
          this.currentPage = 1;

          if (this.filter != oldFilter || !oldActiveFilter || this.filter.length == 0) {
            this.atualizarConsulta();
          }
          this.lockFilter = false;
        }, 800)
      }

    },

    rowClickHandler(record, index) {
      let retorno = {
        $parent: {
          $parent: {
            rowItem: record.state?.row ?? {}
          }
        }
      };

      return retorno;
    },

    getClicTableHeaderFromChildrens() {
      try {
        let retorno = [];
        if (this.childrens) {
          retorno = this.childrens.filter((item) => {
            return item.component == 'ClicTableHeader';
          });
        }
        return retorno || [];
      } catch (e) {
        console.error(`['ClicTable'] Ocorreu um erro no método 'getClicTableHeaderFromChildrens'. \n\n\ Detalhes: ${e}`)
      }
    },

    getClicTableColumnsFromChildrens() {
      try {
        let retorno = [];
        if (this.childrens) {
          let header = this.getClicTableHeaderFromChildrens();
          if (header && header.length) {
            retorno = header[0].childrens.filter((item) => {
              return item.component == 'ClicTableColumnText' || item.component == 'ClicTableColumnCustom';
            });
          }
        }
        return retorno || [];
      } catch (e) {
        console.error(`['ClicTable'] Ocorreu um erro no método 'getClicTableColumnsFromChildrens'. \n\n\ Detalhes: ${e}`)
      }
    },

    getClicTableColumnsDetailFromChildrens() {
      try {
        let retorno = [];

        if (this.childrens) {
          let header = this.getClicTableHeaderFromChildrens();

          if (this.headers) { /*Quando fornecido pela prop Headers, será feita a padronização do formato da linha de detalhes*/
            header = this.converterLinhaDetalhesHeaders(this.headers);
          }

          if (header && header.length) {
            retorno = header[0].childrens.filter((item) => {
              return item.component == 'ClicTableColumnDetail';
            });
          }

        }
        return retorno[0];
      } catch (e) {
        console.error(`['ClicTable'] Ocorreu um erro no método 'getClicTableColumnsDetailFromChildrens'. \n\n\ Detalhes: ${e}`)
      }
    }

  },

  computed: {
    totalRows() {
      return this.totalRegistros;
    },

    totalPages() {
      return Math.ceil(this.totalRegistros / this.itemsPerPage)
    },

    getData(event) {
      eval(event);
    },

  },

  updated() {
    if (this.dataRow && !this.configAxiosContent) {
      this.calcularTotalRegistrosPorPagina();
    };
  },

  beforeDestroy: function () {
    if (this.lodash.has(this.cp, 'props.refreshIntervalSeconds')) {
      clearInterval(this.interval);
    }
  },

  errorCaptured(err, vm, info) {
    console.error(`[ClicTable] Ocorreu uma falha ao renderizar '${vm?.$vnode?.data?.ref || 'Tabela'}'. \n\nDetalhes: ${err.toString()}`);
    this.isLoading = false;
    this.dataRow = [];

    return false
  }
};
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap.scss';

//Exibir colunas e cabecalhos em determinado tamanho de tela
.show-col-xl {
  @extend .table !optional;
  @extend .d-xl-none !optional;
}

.show-header-xl {
  @extend .show-col-xl;
}

.show-col-lg {
  @extend .table !optional;
  @extend .d-lg-none !optional;
}

.show-header-lg {
  @extend .show-col-lg;
}

.show-col-md {
  @extend .table !optional;
  @extend .d-md-none !optional;
}

.show-header-md {
  @extend .show-col-md;
}

.show-col-sm {
  @extend .table !optional;
  @extend .d-sm-none !optional;
}

.show-header-sm {
  @extend .show-col-sm;
}

// Esconder colunas e cabecalhos em determinado tamanho de tela
.hide-col-xl {
  @extend .d-none !optional;
  @extend .d-xl-table-cell !optional;
}

.hide-header-xl {
  @extend .hide-col-xl;
}

.hide-col-lg {
  @extend .d-none !optional;
  @extend .d-lg-table-cell !optional;
}

.hide-header-lg {
  @extend .hide-col-lg;
}

.hide-col-md {
  @extend .d-none !optional;
  @extend .d-md-table-cell !optional;
}

.hide-header-md {
  @extend .hide-col-md;
}

.hide-col-sm {
  @extend .d-none !optional;
  @extend .d-sm-table-cell !optional;
}

.hide-header-sm {
  @extend .hide-col-sm;
}


@media (max-width: 768px) {
  .per-page-select {
    max-width: 30vw
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .per-page-select {
    max-width: 10vw
  }
}

@media (min-width: 1025px) {
  .per-page-select {
    max-width: 10vw
  }
}


.table td:first-child,
.table th:first-child {
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
}

.b-table-row-selected {
  background-color: #f8f9fb !important;
}

.select-col {
  padding-left: 3.5em !important
}

.custom-control .custom-control-input:checked~.custom-control-label::after {
  color: #28a745 !important;
  background-color: rgb(251, 251, 251) !important;
}

.info-pagination {
  white-space: nowrap;
}

th {
  vertical-align: inherit;
  border-bottom: 6px solid #f5f6fa !important;

}

.custom-table tbody tr {
  background-color: white;
  /* Cor de fundo do "card" */
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra para criar o efeito de card */
  margin-bottom: 15px;
  border-radius: 8px;
  /* Cantos arredondados */
  // overflow: hidden;
  /* Para garantir que o conteúdo se ajusta ao card */
  padding: 15px;
}


.table td {
  vertical-align: middle !important;
  border-bottom: 6px solid #f5f6fa !important;
}

@media (max-width: 992px) {
  .table td {
    padding: 0 !important; /* Remove o padding */
    border: none !important; /* Remove a borda, se for o caso */
  }
}

td {
  text-overflow: ellipsis;
  max-width: 800px;
  color: #727171;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  color: var(--cor-principal-claro);
}

table th {
  // text-transform: uppercase;
  // background-color: var(--cor-principal);
  // color: white;  background-color: white;
  color: #727171;
  padding: 10px !important;
  border-bottom: 6px solid #f5f6fa !important;

}

body .table.b-table>tfoot>tr>[aria-sort=none],
body .table.b-table>thead>tr>th[aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

body .table.b-table>tfoot>tr>[aria-sort=ascending],
body .table.b-table>thead>tr>th[aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

body .table.b-table.table-dark>tfoot>tr>[aria-sort=descending],
body .table.b-table.table-dark>thead>tr>[aria-sort=descending],
body .table.b-table>.thead-dark>tr>[aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

.table.b-table>thead>tr>[aria-sort=descending],
.table.b-table>tfoot>tr>[aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}


.page-item.active .page-link {
  background-color: var(--cor-principal-escuro) !important;
  border-color: #ffffff;
  color: rgb(198, 198, 198);
  font-weight: 500;
}

.table-responsive {
  overflow: visible !important;
}

tr:first-child th:first-child {
  border-top-left-radius: 6px;
}


tr:first-child th:last-child {
  border-top-right-radius: 6px;
}


.table thead tr:last-child th {
  border-bottom: 6px solid #f5f6fa !important;
}


.pagination-custom .page-link {
  border-radius: 50px !important;
  margin-left: 10px !important;
  background-color: white !important;
}

.rounded-row {
  border-radius: 8px;
}

.bg-table {
  background-color: #f5f6fa !important;
}
</style>
