import ClicColInterno from '../clicGridLayout/ClicColInternoModel'


import ClicMongoDBChartDocs from './ClicMongoDBChartDocs';

export default {
    ClicMongoDBChart : {
        config      : {icon:"md-stackedbarchart-round", documentation: ClicMongoDBChartDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {

            baseUrl                 : { type: 'string',      default: "https://charts.mongodb.com/charts-clicvenda-oswmifd",    component: { name : 'input' } },
            clicDashboardId         : { type: 'string',      default: undefined,    component: { name : 'input' } },
            filtrosIniciais         : { type: 'object',      default: {},           component: { name : 'code' } },
            filtrosPadrao           : { type: 'object',      default: {},           component: { name : 'code' } },

            ...ClicColInterno,

        },
        events : {

        }
    }
}









