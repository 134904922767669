<template>
  <ClicColInterno :cp="cp" :key="componentKey">
    <div
      :ref="scrollContainerRef"
      @scroll="manipularRolagem"
      @touchmove="manipularRolagemMobile"
      :style="obterTamanhoContainer"
    >

      <b-col v-if="scrollToTop && configAxiosContent" class="bg-white p-2">
        <p 
          class="text-center mt-4" 
          v-if="ultimaPagina && this.items && this.items.length > 0"
        >
          Exibindo todos os resultados
        </p>
        <p 
          class="text-center d-flex justify-content-center mt-4" 
          v-if="items && items.length === 0 && !isLoading"
        >
          Não há itens para exibir
        </p>
        <b-overlay 
          :show="isLoading && !ultimaPagina"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="text-center"
        />
      </b-col>

      <b-list-group :flush="true">
        
        <b-list-group-item 
          @click="click(item)" 
          :id="$utils.getComponentID(cp.id)"
          v-for="(item, index) in items" 
          :key="index"
          :style="itemStyle || 'cursor: pointer;'"
        >

          <MotorS 
            :cp="cp" 
            :childrens="childrens"
            :idTela="idTela"
            :state="{ row: {item, index}, parent: cp}"
          />
        </b-list-group-item>
      </b-list-group>

      <b-col v-if="!scrollToTop && configAxiosContent" class="bg-white p-2">
        <p 
          class="text-center mt-4" 
          v-if="ultimaPagina && this.items && this.items.length > 0"
        >
          Exibindo todos os resultados
        </p>
        <p 
          class="text-center d-flex justify-content-center mt-4" 
          v-if="items && items.length === 0 && !isLoading"
        >
          Não há itens para exibir
        </p>
        <b-overlay 
          :show="isLoading && !ultimaPagina"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="text-center"
        />

      </b-col>
        
    </div>
    
  </ClicColInterno>
</template>

<script>
import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicList',
  mixins: [ComponentMixin],
  data() {
      return {
        public : {
          dataRefresh : this.atualizarConsulta,
          getSelectedRow: this.obterLinhaSelecionada,
        },
        alturaLista: 'calc(100% - 10px)',
        paginaAtual:1,
        isLoading : true,
        novosItens:[],
        ultimaPagina: false,
        filtroAntigo : "",
        scrollContainerRef: `scrollContainerClicList-${this.cp.id}`,

        linhaSelecionada : undefined,

        tamanhoMargemRolagem: 800,
        tamanhoMargemRolagemMobile: 250
      };
  },

  async mounted() {
    if(this.configAxiosContent){
      this.resetarBuscaServidor();
    };

    await this.obterDadosLista();
    
    if(this.configAxiosContent){
      this.calcularTamanhoContainerLista();
    };
    
  },

  computed: {
    
    obterTamanhoContainer() {
      const { configAxiosContent, cp } = this;
      const containerStyle = this.lodash.get(cp, "props.containerStyle", false);
      
      if (configAxiosContent) {
        if (containerStyle && containerStyle != "") {
          return `height: 100%; overflow-y: auto;`;
        } else {
          return `height: ${this.alturaLista}; overflow-y: auto;`;
        };
      };
      
      return '';
    }
  },

  methods: {

    obterLinhaSelecionada(){
      return this.linhaSelecionada;
    },

    click(row) {
      this.linhaSelecionada = row;

      if  (this.lodash.has(this.cp, 'events.click')) {
        try {
          eval(this.cp.events.click);
        } catch (e) {
          console.error(`[ClicList] Falha ao executar evento 'click'. Detalhes: ${e}`);
        }
        
      } else if (this.lodash.has(this.cp, 'events.clickPromise')) {
        this.isLoading = true;
        let click = this.cp.events.clickPromise;
        let script = `
          new Promise((resolve, reject) => {
            ${click}
          });
        `;
        let execucao = eval(script);
        execucao.then(() => {
          this.isLoading = false;
        });
      }
    },

    async atualizarConsulta(){
      try {
        this.setPropValueAndCommit("rows", [])
        this.isLoading  = true;
        this.items      = await this.obterDadosLista();

        this.calcularTamanhoContainerLista();
      
      } catch (e) {
        console.error(`[ClicList] Falha ao atualizar a consulta. Detalhes: ${e}`);
      } finally {
        this.isLoading  = false;
      }      
    },

    resetarBuscaServidor(){
      this.paginaAtual                      = 1;
      this.configAxiosContent.params.fetch  = this.itemsPerPage;
      this.configAxiosContent.params.skip   = 0;
      this.ultimaPagina                     = false;
      this.novosItens                       = [];
      this.items                            = [];
    },

    obterDadosLista() {
      return new Promise((resolve, reject) => {

        if (this.items && !this.configAxiosContent) {
          
          this.isLoading = false;
          resolve(this.items);

        } else if (this.configAxiosContent) {

          if(this.filtroEstaAtivo()){
            this.filtroAntigo = this.configAxiosContent.params.filter;
            this.resetarBuscaServidor();

          }else if(this.filtroAntigo != "" && !this.configAxiosContent.params.filter ){
            this.filtroAntigo = ""
            this.items = [];

          };
            
          let axios = this.configAxiosContent;
          const tipoParametro = this.lodash.get(axios, 'method', 'GET').toUpperCase() == 'GET' ? 'params' : 'data';
          
          axios[tipoParametro].sortBy       = this.sortBy;
          axios[tipoParametro].sortDescAsc  = !this.sortDesc ? 'ASC' : 'DESC';
          axios[tipoParametro].keysFilter   = this.keysFilter;
          axios[tipoParametro].fetch        = this.itemsPerPage;

          this.$axios(axios).then(response => {     
            const pathDados                 = (this.pathItems && this.pathItems.length) ? `data.${this.pathItems}` : 'data';
            this.novosItens                 = this.lodash.get(response, pathDados, []);  

            if(this.novosItens && this.novosItens.length){
              this.ultimaPagina = false;
              
              if(this.scrollToTop){
                this.items.unshift(...this.novosItens);
                this.componentKey += 1; // ajustar

              }else{
                this.items.push(...this.novosItens);
              };

              if(this.primeiraPagina()){
                this.componentKey += 1;
              };

            }else{
              this.ultimaPagina = true;
            };
            
            this.isLoading = false;
            
            resolve(this.items);
          });          
        }else{
          this.isLoading = false;
          resolve(this.items);
        }          
      });
    },

    mudarPagina(novaPagina) {
      if (this.configAxiosContent) {
        this.paginaAtual  = novaPagina;

        this.configAxiosContent.params.fetch  = this?.itemsPerPage;
        let skip                              = (this.paginaAtual-1) * this.itemsPerPage;

        if (skip != this.configAxiosContent.params.skip){
          this.configAxiosContent.params.skip = skip;          
        }
        this.atualizarConsulta();
      };
    },

    manipularRolagem() {
      if(this.configAxiosContent){
        const scrollContainer = this.$refs[this.scrollContainerRef];
        const margin          = this.primeiraPagina() || this.ultimaPagina ? 5 : this.tamanhoMargemRolagem;
        
        const topoContainer   = (scrollContainer.scrollTop) === 0;
        const rodapeContainer = scrollContainer.scrollTop + scrollContainer.clientHeight + margin >= scrollContainer.scrollHeight;


        if(topoContainer && this.scrollToTop && !this.ultimaPagina && !this.isLoading){
          this.mudarPagina(this.paginaAtual+1);
        };

        if (rodapeContainer && !this.scrollToTop && !this.ultimaPagina && !this.isLoading) {
          this.mudarPagina(this.paginaAtual+1);
        };
      }
 
    },

    manipularRolagemMobile(event) {
      if(this.configAxiosContent){
        const scrollContainer = this.$refs[this.scrollContainerRef];
        const margin          = this.primeiraPagina() || this.ultimaPagina ? 5 : this.tamanhoMargemRolagemMobile;

        if (!this.scrollToTop && scrollContainer.scrollTop + scrollContainer.clientHeight + margin >= scrollContainer.scrollHeight) {
          this.mudarPagina(this.paginaAtual+1);
        };

      }
 
    },

    calcularTamanhoContainerLista() {
      const scrollContainer = this.$refs[this.scrollContainerRef];

      if (this.primeiraPagina()) {
        this.alturaLista = `${scrollContainer.scrollHeight - 25}px`;

      }else{
        this.alturaLista = this.alturaLista;
      }
    
      if(this.scrollToTop){        
        scrollContainer.scrollTop = ( scrollContainer.scrollHeight / this.paginaAtual);
      };

    },

    primeiraPagina(){
      return this.paginaAtual === 1;
    },

    filtroEstaAtivo() {
      return this.configAxiosContent.params.filter  &&
        this.configAxiosContent.params.filter != "" &&
        this.filtroAntigo != this.configAxiosContent.params.filter;
    }

  },

};
</script>

<style>
.card-body {
  padding-top: 0;
}
</style>